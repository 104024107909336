import React, { useEffect, useRef, useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HubspotForm from "react-hubspot-form"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import ResourceLibraryRecommended from "../components/resourceLibrary/ResourceLibraryRecommended"

const DownLoadTrigger = React.memo(({ filePath, onClose }) => {
  const anchorRef = useRef(null)

  useEffect(() => {
    anchorRef.current.click()
    onClose()
  }, [])

  return (
    <a
      ref={anchorRef}
      href={filePath}
      download={"EnerBank_" + filePath.split("/").pop()}
    />
  )
})

const WhitePaper = ({ data: { whitePapersJson } }) => {
  const [startDownload, setStartDownload] = useState(false)
  const hubspotFormId = "060932d7-dd0a-4a2c-82f3-22a6f3cfa5fd"
  const filePath = whitePapersJson.filePath

  const handlePdfDownload = event => {
    setStartDownload(current =>
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted" &&
      event.data.id === hubspotFormId
        ? true
        : current
    )
  }

  useEffect(() => {
    window.addEventListener("message", handlePdfDownload)
    return () => {
      window.removeEventListener("message", handlePdfDownload)
    }
  }, [])

  return (
    <Layout>
      <Seo
        title={whitePapersJson.title}
        description={
          "EnerBank USA is America's home improvement lender of choice. We help contractors grow their business and homeowners have the home of their dreams."
        }
      />
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row mb-4 mb-lg-5">
            <div className="col text-center text-lg-left ">
              <Link
                to="/resource-library/"
                className="btn btn-outline-primary f-bold pt-2 pb-2 pr-4 pl-4 rl-home"
              >
                Resource Library Home
              </Link>
            </div>
          </div>

          <div className="item-content d-lg-flex">
            <div className="row">
              <div className="item-image col-8 col-sm-4 mb-5 d-flex justify-content-center align-items-center d-lg-block ml-auto mr-auto">
                <GatsbyImage
                  imgClassName="img-fluid rounded-start"
                  alt="Image"
                  image={getImage(whitePapersJson.image)}
                />
              </div>
              <div className="item-text col-lg-4 px-3 pr-sm-5">
                <h2 className="mb-4 text-center text-lg-left mobile-only-h2 desktop-only-rl-page-h2">
                  {whitePapersJson.title}
                </h2>
                <div className="col text-center mb-4">
                  <a
                    href="#get-your-free-copy"
                    className="btn btn-primary f-bold pt-1 pb-1 pr-3 pl-3 d-lg-none"
                  >
                    Get Your Free Copy
                  </a>
                </div>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: whitePapersJson.text }}
                />
              </div>
              <div className="col-lg-4">
                <div className="item-form p-4 bg-light rounded shadow-sm eb-lib-download">
                  <h3 className="text-center mb-3">Get Your Free Copy</h3>
                  <HubspotForm
                    portalId="381510"
                    formId={hubspotFormId}
                    onSubmit={form => this.handleFormSubmit}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {startDownload && (
        <DownLoadTrigger
          filePath={filePath}
          onClose={() => setStartDownload(false)}
        />
      )}
      {/* <ResourceLibraryRecommended /> */}
    </Layout>
  )
}

export default WhitePaper

export const query = graphql`
  query WhitePaperQuery($link: String) {
    whitePapersJson(link: { eq: $link }) {
      title
      text
      filePath
      image {
        childImageSharp {
          gatsbyImageData(formats: AUTO, placeholder: BLURRED)
        }
      }
    }
  }
`
